
import { initializeApp } from "firebase/app";

import { get, getDatabase } from "firebase/database";


function StartFirebase (){
    
    const firebaseConfig = {

        apiKey: "AIzaSyAbX8bmvyVSfuh5alJyJ9KI83R15tJRXCU",
      
        authDomain: "fabc-lms-actions.firebaseapp.com",
      
        databaseURL: "https://fabc-lms-actions-default-rtdb.firebaseio.com",
      
        projectId: "fabc-lms-actions",
      
        storageBucket: "fabc-lms-actions.appspot.com",
      
        messagingSenderId: "819206755323",
      
        appId: "1:819206755323:web:fc6105946d7275109f315e"
      
      };
      
      
      
      const app = initializeApp(firebaseConfig);


      return getDatabase(app)

}

  // const app = initializeApp(firebaseConfig);
  // const db = getDatabase(app);

export default StartFirebase;

