

//   useEffect(() => {
//     // Get the latest action from the actions array
//     const newLatestAction = actions.length > 0 ? actions[actions.length - 1] : null;

//     // If the latest action has changed, update the state
//     if (newLatestAction !== latestAction) {
//       setLatestAction(newLatestAction);

//       const sendToFirebase = async () => {
//         try {
//           const Email = seeker_email;
//           console.log("Email", Email);
//           console.log('Latest Action:', newLatestAction);

//           const options = {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               Email,
//               course: "Youtube",
//               action: newLatestAction, // Send only the latest action
//             }),
//           };

//           const res = await fetch("https://fabc-lms-actions-default-rtdb.firebaseio.com/UserAction.json", options);

//           if (res.ok) {
//             console.log("Request sent successfully");
//           } else {
//             console.log("Request failed with status:", res.status);
//           }
//         } catch (error) {
//           console.error("Error sending request:", error);
//         }
//       };

//       // Call the async function when actions or seeker_email change
//       sendToFirebase();
//     }
//   }, [actions, seeker_email, latestAction]);






//   const Email = seeker_email
//   console.log("Email",Email)

//   const options ={
//     method:"POST",
//     headers:{
//         "Content-Type":"application/json"
//     },
//     body:JSON.stringify({
//         Email,actions
//     })
//   }


//   const res = fetch("https://fabc-lms-actions-default-rtdb.firebaseio.com/UserAction.json",
//   options
//   )
//   if(res){
//     console.log("sent")
//   }
//   else{
//     console.log("not sent")
//   }



///////////////////----------------------------------my orginal code


// import React, { useEffect, useState } from 'react';
// import YouTube from 'react-youtube';
// import { useDispatch, useSelector } from 'react-redux';
// import { handleVideoAction } from './store';
// import { setValidatorEmail } from "./store";
// import FirebaseSent from './Firebase/FirebaseSent';


// import { PlayIcon, PauseIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
// import { FaPlay, FaPause, FaFastForward, FaFastBackward } from 'react-icons/fa'

// const formatTime = (seconds) => {
//   const hours = Math.floor(seconds / 3600);
//   const minutes = Math.floor((seconds % 3600) / 60);
//   const remainingSeconds = Math.floor(seconds % 60);

//   const formatNumber = (num) => (num < 10 ? `0${num}` : num);

//   return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(remainingSeconds)}`;
// };




// const VideoPlayer = ({ videoId, courseName, email  }) => {
//   const dispatch = useDispatch();
//   const watchedTime = useSelector((state) => state.watchedTime);
//   const actions = useSelector((state) => state.actions);
//   const [latestAction, setLatestAction] = useState(null);


//   const [player, setPlayer] = useState(null);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);    
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [isPlayerReady, setIsPlayerReady] = useState(false);

//   const seeker_email = useSelector((state) => state.seeker_email);
//   console.log("seeker_email",seeker_email)

 
//   const onReady = (event) => {
//     setPlayer(event.target);
//     setDuration(event.target.getDuration());
//     setIsPlayerReady(true);
//   };
  

//   const updateCurrentTime = () => {
//     if (player && isPlaying && player.getCurrentTime) {
//       const newCurrentTime = player.getCurrentTime();
//       setCurrentTime(newCurrentTime);

//       const newWatchedTime = parseFloat(watchedTime) + (newCurrentTime - currentTime);
//       if (Number.isFinite(newWatchedTime)) {
//         dispatch(handleVideoAction('UPDATE_WATCHED_TIME', newWatchedTime.toFixed(2)));
//       } else {
//         console.error('Invalid watchedTime value:', newWatchedTime);
//       }
//     }
//   };

//   const onStateChange = (event) => {
//     const playerState = event.data;
//     setIsPlaying(playerState === YouTube.PlayerState.PLAYING);
//   };

//   const onPlayPause = () => {
//     if (player) {
//       const action = isPlaying ? 'Pause' : 'Play';
//       dispatch(
//         handleVideoAction('STORE_ACTION', {
//           courseName,
//           email,
//           action,
//           time: new Date().toLocaleString(),
//           currentTime: formatTime(currentTime),
//         })
//       );
//       isPlaying ? player.pauseVideo() : player.playVideo();
//       setIsPlaying(!isPlaying);
//     }
//   };

//   const onSkip = (seconds) => {
//     if (player && typeof player.seekTo === 'function') {
//       const newCurrentTime = currentTime + seconds;
//       player.seekTo(newCurrentTime, true);
//       setCurrentTime(newCurrentTime);

//       const action = seconds > 0 ? `Skip Forward ${seconds} seconds` : `Skip Backward ${Math.abs(seconds)} seconds`;
//       dispatch(
//         handleVideoAction('STORE_ACTION', {
//           courseName,
//           email,
//           action,
//           time: new Date().toLocaleString(),
//           currentTime: formatTime(newCurrentTime),
          
//         })
//       );
//     }
//   };

//   useEffect(() => {
//     const initializePlayer = () => {
//       const newPlayer = new window.YT.Player('player', {
//         videoId,
//         events: {
//           onReady,
//           onStateChange,
//         },
//       });

//       setPlayer(newPlayer);
//     };

//     if (typeof window.YT === 'undefined' || typeof window.YT.Player === 'undefined') {
//       const tag = document.createElement('script');
//       tag.src = 'https://www.youtube.com/iframe_api';
//       const firstScriptTag = document.getElementsByTagName('script')[0];
//       firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

//       window.onYouTubeIframeAPIReady = initializePlayer;

//       return () => {
//         delete window.onYouTubeIframeAPIReady;
//       };
//     } else {
//       initializePlayer();
//     }
//   }, [videoId]);

//   useEffect(() => {
//     const storedWatchedTime = localStorage.getItem('watchedTime');
//     if (storedWatchedTime) {
//       dispatch(handleVideoAction('UPDATE_WATCHED_TIME', parseFloat(storedWatchedTime)));
//     }
//   }, [dispatch]);

//   useEffect(() => {
//     let intervalId;

//     if (isPlaying && player && isPlayerReady) {
//       intervalId = setInterval(updateCurrentTime, 1000);
//     }

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [isPlaying, player, isPlayerReady]);
//   const percentageWatched = Math.min((currentTime / duration) * 100, 100);
//   //  const fullyWatched = percentageWatched >= 100;
//     const fullyWatched = percentageWatched >= 99.55;




//   useEffect(() => {
//     console.log('Actions:', actions);
//   }, [actions]);
//   console.log('Actions:', actions);







// // useEffect(() => {
// //     console.log('Current Actions:', actions);

// //     // Only proceed if there is at least one action
// //     if (actions.length > 0) {
// //       const sendToFirebase = async () => {
// //         try {
// //           const Email = seeker_email;
// //           console.log("Email", Email);

// //           const latestAction = actions[actions.length - 1];

// //           const options = {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //             },
// //             body: JSON.stringify({
// //             //   Email,
// //             //   course: "Youtube",
// //             //   action: latestAction,
// //            actions
// //             }),
// //           };

// //           const res = await fetch("https://fabc-lms-actions-default-rtdb.firebaseio.com/UserAction/action.json", options);
 
// //           if (res.ok) {
// //             console.log("Request sent successfully");
// //           } else {
// //             console.log("Request failed with status:", res.status);
// //           }
// //         } catch (error) {
// //           console.error("Error sending request:", error);
// //         }
// //       };

// //       // Call the async function when actions change
// //       sendToFirebase();
// //     }
// //   }, [actions, seeker_email]);


// const progressLineStyle = {
//   width: `${(percentageWatched * 100).toFixed(2)}%`,
//   background: 'linear-gradient(to right, #00ccff 0%, #800080 100%)',
// };



  
//   return (
//     // <div>
//     //   <YouTube videoId={videoId} opts={{ width: '640', height: '360' }} onReady={onReady} />
//     //   {/* <div>
//     //     <p>Current Time: {formatTime(currentTime)}</p>
//     //     <p>Full Duration: {formatTime(duration)}</p>
//     //     <p>Watched Time: {formatTime(watchedTime)}</p>
//     //     <p>Is Playing: {isPlaying ? 'Yes' : 'No'}</p>
//     //     <p>Fully Watched: {fullyWatched ? 'Yes' : 'No'}</p>
//     //     <p>Percentage Watched: {percentageWatched.toFixed(2)}%</p>
//     //   <p>  <button onClick={onPlayPause}>{isPlaying ? 'Pause' : 'Play'}</button></p>
//     //   <p>  <button onClick={() => onSkip(10)}>Skip Forward 10 seconds</button></p>
//     //   <p>  <button onClick={() => onSkip(-10)}>Skip Backward 10 seconds</button></p>
//     //   </div> */}
//     //         <FirebaseSent email={email} courseName={courseName} actions={actions} />
//     //      <div className="mt-4">
//     //     <p className="mb-2">Current Time: {formatTime(currentTime)}</p>
//     //     <p className="mb-2">Full Duration: {formatTime(duration)}</p>
//     //     <p className="mb-2">Watched Time: {formatTime(watchedTime)}</p>
//     //     <p className="mb-2">Is Playing: {isPlaying ? 'Yes' : 'No'}</p>
//     //     <p className="mb-2">Fully Watched: {fullyWatched ? 'Yes' : 'No'}</p>
//     //     <p className="mb-2">Percentage Watched: {percentageWatched.toFixed(2)}%</p>
//     //     <button
//     //       className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
//     //       onClick={onPlayPause}
//     //     >
//     //       {isPlaying ? 'Pause' : 'Play'}
//     //     </button>
//     //     <button
//     //       className="bg-green-500 text-white py-2 px-4 rounded mr-2"
//     //       onClick={() => onSkip(10)}
//     //     >
//     //       Skip Forward 10 seconds
//     //     </button>
//     //     <button
//     //       className="bg-red-500 text-white py-2 px-4 rounded"
//     //       onClick={() => onSkip(-10)}
//     //     >
//     //       Skip Backward 10 seconds
//     //     </button>
//     //   </div>
//     // </div>





//      <div className="relative flex flex-col w-full h-96 bg-black rounded-lg overflow-hidden shadow-lg">
//     <YouTube videoId={videoId} opts={{ width: '100%', height: '100%' }} onReady={onReady} />
//     <div className="absolute bottom-0 left-0 right-0 flex justify-between items-center bg-gray-800 p-4 rounded-b-lg">
//       <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 mr-4" onClick={onPlayPause}>
//         {isPlaying ? <FaPause size={24} color="white" /> : <FaPlay size={24} color="white" />}
//       </button>
//       <div className="flex flex-col items-center text-white text-sm">
//         <p>Current Time: {formatTime(currentTime)}</p>
//         <p>Full Duration: {formatTime(duration)}</p>
//       </div>
//       <div className="flex items-center w-full">
//         <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 mr-2" onClick={() => onSkip(-10)}>
//           <FaFastBackward size={24} color="white" />
//         </button>
//         <div className="flex w-full h-2 rounded-full bg-gray-700">
//           <div className="relative h-full" style={progressLineStyle}></div>
//         </div>
//         <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 ml-2" onClick={() => onSkip(10)}>
//           <FaFastForward size={24} color="white" />
//         </button>
//       </div>
//     </div>
//   </div>
//   );
// };

// export default VideoPlayer;








////----------------------



///////////---------------------
















import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { useDispatch, useSelector } from 'react-redux';
import { handleVideoAction } from './store';
import { FaPlay, FaPause, FaFastForward, FaFastBackward, FaVolumeUp, FaVolumeDown, FaExpand } from 'react-icons/fa';
import FirebaseSent from './Firebase/FirebaseSent';

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formatNumber = (num) => (num < 10 ? `0${num}` : num);

  return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(remainingSeconds)}`;
};

const VideoPlayer = ({ videoId, courseName, email,contentTiming,onWatchedTimeUpdate ,onCurrentTimeChange,onWatchedPercentageUpdate ,course_id }) => {


  const dispatch = useDispatch();
  const watchedTime = useSelector((state) => state.watchedTime);
  const actions = useSelector((state) => state.actions);

  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [volume, setVolume] = useState(50);
  const [newWatchedTime, setNewWatchedTime] = useState(0);
  const [CompleteWatchedPercentage, setCompleteWatchedPercentage] = useState(0);

console.log("contentTiming",contentTiming)
console.log("course_id  video player",course_id)

const convertToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};


  const onReady = (event) => {
    setPlayer(event.target);
    setDuration(event.target.getDuration());
    setIsPlayerReady(true);
    event.target.setVolume(volume);
    event.target.setPlaybackQuality('hd1080'); 
    event.target.setOption('controls', 0); 
    event.target.setOption('disablekb', 1); 
    event.target.setOption('fs', 0); 
    event.target.setOption('iv_load_policy', 3);

    
  };



  let newCurrentTime; 


  // const updateCurrentTime = () => {
  //   if (player && isPlaying && player.getCurrentTime) {
  //     const newCurrentTime = player.getCurrentTime();
  //     setCurrentTime(newCurrentTime);

  //     // if (newCurrentTime > watchedTime || watchedTime === undefined) {
  //     //   const newWatchedTime = parseFloat(watchedTime) + (newCurrentTime - currentTime);
  //     //   console.log("newWatchedTime",newWatchedTime)
  //     // }
  

  //     const newWatchedTime = (newCurrentTime > watchedTime || watchedTime === undefined)
  //     ? newCurrentTime
  //     : watchedTime;


  //     console.log("newCurrentTime:", newCurrentTime); // Add this line to log newCurrentTime
  //     // if (newCurrentTime > Math.min(watchedTime, duration)) {
  //     //   // Only update watchedTime if newCurrentTime exceeds the watched time or duration
  //     //  newWatchedTime = newCurrentTime - currentTime;
  //     // } else {
  //     //   // If newCurrentTime is less than watched time, keep the existing watched time
  //     //   newWatchedTime = Math.min(watchedTime, duration);
  //     // }
  //     console.log("newWatchedTime", newWatchedTime);
 

  //     // const newWatchedTime = parseFloat(watchedTime) + (newCurrentTime - currentTime);
  //     if (Number.isFinite(newWatchedTime)) {
  //       dispatch(handleVideoAction('UPDATE_WATCHED_TIME', newWatchedTime.toFixed(2)));
  //       onWatchedTimeUpdate(newWatchedTime);
  //       onCurrentTimeChange(newCurrentTime);
  //       localStorage.setItem('watchedTime', newWatchedTime.toString());
  //     } else {
  //       console.error('Invalid watchedTime value:', newWatchedTime);
  //     }
  //   }
  // };



console.log(duration,"duration")



const updateCurrentTime = () => {
  if (player && isPlaying && player.getCurrentTime) {
    const newCurrentTime = player.getCurrentTime();
    setCurrentTime(newCurrentTime);

    const newWatchedTime = (newCurrentTime > watchedTime || watchedTime === undefined)
      ? newCurrentTime
      : watchedTime;

    if (Number.isFinite(newWatchedTime)) {
      dispatch(handleVideoAction('UPDATE_WATCHED_TIME', newWatchedTime.toFixed(2)));
      onWatchedTimeUpdate(newWatchedTime);
      onCurrentTimeChange(newCurrentTime);
      localStorage.setItem(`watchedTime_${course_id}`, newWatchedTime.toString());
    } else {
      console.error('Invalid watchedTime value:', newWatchedTime);
    }

    console.log("newCurrentTime:", newCurrentTime);


    const CompleteWatchedPrecentage = Math.min((newWatchedTime / duration) * 100, 100);
    console.log("CompleteWatchedPrecentage", CompleteWatchedPrecentage);

  

    onWatchedPercentageUpdate(CompleteWatchedPrecentage);


    const newCompleteWatchedPercentage = Math.min((newWatchedTime / duration) * 100, 100);
      setCompleteWatchedPercentage(newCompleteWatchedPercentage);
      console.log("CompleteWatchedPercentage", newCompleteWatchedPercentage);

      onWatchedPercentageUpdate(newCompleteWatchedPercentage);
  }
}  

console.log("CompleteWatchedPercentage",CompleteWatchedPercentage)



useEffect(() => {
 
  updateCurrentTime();
}, [player, isPlaying, watchedTime, duration, setCurrentTime, handleVideoAction, onWatchedTimeUpdate, onCurrentTimeChange, onWatchedPercentageUpdate]);

console.log("CompleteWatchedPercentage outside function:", CompleteWatchedPercentage);









useEffect(() => {
  calculateNewWatchedTime();
}, [newCurrentTime]);

const calculateNewWatchedTime = () => {
 
  if (newCurrentTime > watchedTime) {
  
    if (newCurrentTime <= duration) {
      setNewWatchedTime(newCurrentTime);
    }

    else {
      setNewWatchedTime(duration);
    }
  }
  
  else {
    setNewWatchedTime(watchedTime);
  }
};

console.log('New watched time:', newWatchedTime);













  const onStateChange = (event) => {
    const playerState = event.data;
    setIsPlaying(playerState === YouTube.PlayerState.PLAYING);
  };


  
  const onPlayPause = () => {
    if (player) {
      const action = isPlaying ? 'Pause' : 'Play';
      dispatch(
        handleVideoAction('STORE_ACTION', {
          courseName,
          email,
          action,
          time: new Date().toLocaleString(),
          currentTime: formatTime(currentTime),
          percentageWatched:(percentageWatched.toFixed(2)) ,
          FullyWatched: (fullyWatched ? 'Yes' : 'No'),
          FullDuration: (formatTime(duration))
        })
      );
      isPlaying ? player.pauseVideo() : player.playVideo();
      setIsPlaying(!isPlaying);
    }
  };

  const onSkip = (seconds) => {
    if (player && typeof player.seekTo === 'function') {
      const newCurrentTime = currentTime + seconds;
      player.seekTo(newCurrentTime, true);
      setCurrentTime(newCurrentTime);

      const action = seconds > 0 ? `Skip Forward ${seconds} seconds` : `Skip Backward ${Math.abs(seconds)} seconds`;
      dispatch(
        handleVideoAction('STORE_ACTION', {
          courseName,
          email,
          action,
          time: new Date().toLocaleString(),
          currentTime: formatTime(newCurrentTime),
          percentageWatched:(percentageWatched.toFixed(2)) ,
          FullyWatched: (fullyWatched ? 'Yes' : 'No'),
          FullDuration: (formatTime(duration))
        })
      );
    }
};



  const onVolumeChange = (value) => {
    if (player) {
      setVolume(value);
      player.setVolume(value);
    }
  };

  const toggleFullScreen = () => {
    if (player) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        player.getIframe().requestFullscreen();
      }
    }
  };

  useEffect(() => {
    const initializePlayer = () => {
      const newPlayer = new window.YT.Player('player', {
        videoId,
        events: {
          onReady,
          onStateChange,
        },
      });

      setPlayer(newPlayer);
    };

    if (typeof window.YT === 'undefined' || typeof window.YT.Player === 'undefined') {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = initializePlayer;

      return () => {
        delete window.onYouTubeIframeAPIReady;
      };
    } else {
      initializePlayer();
    }
  }, [videoId]);


  /////----------------------------------------------------------------
  // const contentTimingInSeconds = convertToSeconds(contentTiming);    

  // if (currentTime  >= contentTimingInSeconds) {
  //   player.pauseVideo();
  // }


  /////////----------------------------------------------------------



  useEffect(() => {
    if (player && typeof player.seekTo === 'function') {
   
      const contentTimingInSeconds = convertToSeconds(contentTiming);
  console.log("contentTimingInSeconds",contentTimingInSeconds)
    
      player.seekTo(contentTimingInSeconds, true);
      player.pauseVideo();
      setIsPlaying(false);
      
    }
  }, [player, contentTiming]);


  

  useEffect(() => {
    const storedWatchedTime = localStorage.getItem(`watchedTime_${course_id}`);
    if (storedWatchedTime) {
      dispatch(handleVideoAction('UPDATE_WATCHED_TIME', parseFloat(storedWatchedTime)));
    }
  }, [dispatch]);


  useEffect(() => {
    let intervalId;
    
    if (isPlaying && player && isPlayerReady) {
      intervalId = setInterval(updateCurrentTime, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isPlaying, player, isPlayerReady]);


// const CompleteWatchedPrecentage   = Math.min((newWatchedTime / duration) * 100, 100);


// console.log("CompleteWatchedPrecentage",CompleteWatchedPrecentage)


  const percentageWatched = Math.min((currentTime / duration) * 100, 100);
  const fullyWatched = percentageWatched >= 99.55;

  useEffect(() => {
    console.log('Actions:', actions);
  }, [actions]);

  const progressLineStyle = {
    width: `${(currentTime / duration) * 100}%`,
    background: 'linear-gradient(to right, #00ccff 0%, #800080 100%)',
  };





  useEffect(() => {
    
    localStorage.setItem(`watchedTime_${course_id}`, newWatchedTime.toString());
  }, [newWatchedTime]);




  return (

    // <div>
    //   <YouTube videoId={videoId} opts={{ width: '640', height: '360' }} onReady={onReady} />
    //   {/* <div>
    //     <p>Current Time: {formatTime(currentTime)}</p>
    //     <p>Full Duration: {formatTime(duration)}</p>
    //     <p>Watched Time: {formatTime(watchedTime)}</p>
    //     <p>Is Playing: {isPlaying ? 'Yes' : 'No'}</p>
    //     <p>Fully Watched: {fullyWatched ? 'Yes' : 'No'}</p>
    //     <p>Percentage Watched: {percentageWatched.toFixed(2)}%</p>
    //   <p>  <button onClick={onPlayPause}>{isPlaying ? 'Pause' : 'Play'}</button></p>
    //   <p>  <button onClick={() => onSkip(10)}>Skip Forward 10 seconds</button></p>
    //   <p>  <button onClick={() => onSkip(-10)}>Skip Backward 10 seconds</button></p>
    //   </div> */}
    //         <FirebaseSent email={email} courseName={courseName} actions={actions} />
    //      <div className="mt-4">
    //     <p className="mb-2">Current Time: {formatTime(currentTime)}</p>
    //     <p className="mb-2">Full Duration: {formatTime(duration)}</p>
    //     <p className="mb-2">Watched Time: {formatTime(watchedTime)}</p>
    //     <p className="mb-2">Is Playing: {isPlaying ? 'Yes' : 'No'}</p>
    //     <p className="mb-2">Fully Watched: {fullyWatched ? 'Yes' : 'No'}</p>
    //     <p className="mb-2">Percentage Watched: {percentageWatched.toFixed(2)}%</p>
    //     <button
    //       className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
    //       onClick={onPlayPause}
    //     >
    //       {isPlaying ? 'Pause' : 'Play'}
    //     </button>
    //     <button
    //       className="bg-green-500 text-white py-2 px-4 rounded mr-2"
    //       onClick={() => onSkip(10)}
    //     >
    //       Skip Forward 10 seconds
    //     </button>
    //     <button
    //       className="bg-red-500 text-white py-2 px-4 rounded"
    //       onClick={() => onSkip(-10)}
    //     >
    //       Skip Backward 10 seconds
    //     </button>
    //   </div>
    // </div>


    <div className="relative flex flex-col w-full h-96 bg-black rounded-lg overflow-hidden shadow-lg">
      <YouTube videoId={videoId} style={{ pointerEvents: 'none' }} opts={{  width: '730', height: '330', playerVars: { controls: 0 } }} onReady={onReady} />
         <FirebaseSent email={email} courseName={courseName} actions={actions}  watchedPercentage={CompleteWatchedPercentage}  />

      <div className="absolute bottom-0 left-0 right-0 flex justify-between items-center bg-gray-800 p-1 rounded-b-lg">
        <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 mr-4" onClick={onPlayPause}>
          {isPlaying ? <FaPause size={24} color="white" /> : <FaPlay size={24} color="white" />}
        </button>
        <div className="flex flex-col items-center text-white text-sm">
       
          <p>{formatTime(currentTime)}/{formatTime(duration )}</p>
        </div>
        <div className="flex items-center w-full">
          <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 mr-2" onClick={() => onSkip(-10)}>
            <FaFastBackward size={24} color="white" />
          </button>
          <div className="flex w-full h-2 rounded-full bg-gray-700">
            <div className="relative h-full" style={progressLineStyle}></div>
          </div>
          <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 ml-2" onClick={() => onSkip(10)}>
            <FaFastForward size={24} color="white" />
          </button>
        </div>
        <div className="flex items-center">
          <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 mr-2" onClick={() => onVolumeChange(volume + 10)}>
            <FaVolumeUp size={24} color="white" />
          </button>
          <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2 mr-2" onClick={() => onVolumeChange(volume - 10)}>
            <FaVolumeDown size={24} color="white" />
          </button>
          <button type="button" className="rounded-full bg-gradient-to-r from-purple-400 to-pink-400 p-2" onClick={toggleFullScreen}>
            <FaExpand size={24} color="white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
