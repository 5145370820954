// // store.js
// import { legacy_createStore } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // Use local storage

// const initialState = {
//   seeker_email: '',
//   // employer_email: '',
//   // admin_email:'',
//   validator_email:''
// };

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'SET_SEEKER_EMAIL':
//       return {
//         ...state,
//         seeker_email: action.payload,
//       };
//     case 'SET_EMPLOYER_EMAIL':
//       return {
//         ...state,
//         employer_email: action.payload,
//       };
//   //   case 'SET_ADMIN_EMAIL':
//   //       return {
//   //         ...state,
//   //         admin_email: action.payload,
//   //   };
//   //   case 'SET_VALIDATOR_EMAIL':
//   //     return {
//   //       ...state,
//   //       validator_email: action.payload,
//   // };
//     default:
//       return state;
//   }
// };
// // export const setSeekerEmail = (email) => ({
// //   type: 'SET_SEEKER_EMAIL',
// //   payload: email,
// // });

// // export const setEmployerEmail = (email) => ({
// //   type: 'SET_EMPLOYER_EMAIL',
// //   payload: email,
// // });

// // export const setAdminEmail = (email) => ({
// //   type: 'SET_ADMIN_EMAIL',
// //   payload: email,
// // });
// export const setValidatorEmail = (email) => ({
//   type: 'SET_VALIDATOR_EMAIL',
//   payload: email,
// });

// const persistConfig = {
//   key: 'root', // Key for the persistor
//   storage, // Use local storage
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = legacy_createStore(persistedReducer);

// const persistor = persistStore(store);

// export { store, persistor };


// // store.js
// import { createStore } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const initialState = {
//   validator_email: '',
// };

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'SET_VALIDATOR_EMAIL':
//       return {
//         ...state,
//         validator_email: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export const setValidatorEmail = (email) => ({
//   type: 'SET_VALIDATOR_EMAIL',
//   payload: email,
// });

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = createStore(persistedReducer);

// const persistor = persistStore(store);

// export { store, persistor };





// import { createStore, applyMiddleware } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import thunk from 'redux-thunk';

// const initialState = {
//   seeker_email: '',
//   watchedTime: 0,
//   actions: [],
// };

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'SET_SEEKER_EMAIL':
//       return {
//         ...state,
//         seeker_email: action.payload,
//       };
//     case 'UPDATE_WATCHED_TIME':
//       return {
//         ...state,
//         watchedTime: action.payload,
//       };
//     case 'STORE_ACTION':
//       return {
//         ...state,
//         actions: [...state.actions, action.payload],
//       };
//     default:
//       return state;
//   }
// };

// export const setSeekerEmail = (email) => ({
//   type: 'SET_SEEKER_EMAIL',
//   payload: email,
// });



// export const updateWatchedTime = (watchedTime) => ({
//   type: 'UPDATE_WATCHED_TIME',
//   payload: watchedTime,
// });

// export const storeAction = (action) => ({
//   type: 'STORE_ACTION',
//   payload: action,
// });

// export const handleVideoAction = (type, payload) => ({
//   type,
//   payload,
// });

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = createStore(persistedReducer, initialState, applyMiddleware(thunk));

// const persistor = persistStore(store);

// export { store, persistor };






import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const initialState = {
  user_role:'',
  seeker_email: '',
  admin_email:'',
  watchedTime: 0,
  actions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEEKER_EMAIL':
      return {
        ...state,
        seeker_email: action.payload,
      };
      case'SET_ADMIN_EMAIL':
        return {
          ...state,
          admin_email:action.payload,
        };
        case'SET_USER_ROLE':
        return {
          ...state,
          user_role:action.payload,
        };
    case 'UPDATE_WATCHED_TIME':
      return {
        ...state,
        watchedTime: action.payload,
      };
    case 'STORE_ACTION':
      return {
        ...state,
        actions: [...state.actions, action.payload],
      };
    default:
      return state;
  }
};

export const setSeekerEmail = (email) => ({
  type: 'SET_SEEKER_EMAIL',
  payload: email,
});



export const setAdminEmail= (email)=>({
  type:'SET_ADMIN_EMAIL',
  payload:email,
});

export const setUserRole= (data)=>({
  type:'SET_USER_ROLE',
  payload:data,
});


export const updateWatchedTime = (watchedTime) => ({
  type: 'UPDATE_WATCHED_TIME',
  payload: watchedTime,
});


export const storeAction = (action) => ({
  type: 'STORE_ACTION',
  payload: action,
});

export const handleVideoAction = (type, payload) => ({
  type,
  payload,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, initialState, applyMiddleware(thunk));

const persistor = persistStore(store);

export { store, persistor };
