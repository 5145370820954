// import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
// import Header from './Header';
// // import Home from './Home';
// import Footer from './Footer';
// import Chatui from './Chatui';
// import Certificate from './Certificate';
// // import SeekerResponse from './SeekerResponse';
// import Notification from './Notification';
// import Aboutcompany from './Aboutcompany';
// //import CourseVideo from './CourseVideo';
// import Lms from './Lms';
// import Footertwo from './Footertwo'
// import Validators from './Validators';
// import Questionpage from './Questionpage';
// import Dummy from './Dummy';
// import Manage from './Manage';
// import Wallet from './Wallet';
// import Assessment from './Assesment';
// import Popup from './Popup';
// import SkillAssessment from './SkillAssessment';
// // import SkillId from './SkillId';
// import PostSkillAssessment from './PostSkillAssessment';
// import Congratulation from './Congratulation';
// import CourseVideos from './CourseVideos';
// import Register from './Register';
// import ForgetPassword from './ForgetPassword';
// import Login from './Login'
// import ResetPassword from './ResetPassword';
// import Verify from './Verify';
// import VerifyLogin from './VerifyLogin';
// import Homee from './PreCourse';
// import PreCourse from './PreCourse';
// import VideoPlayer from './VideoPlayer';
// import ActionsPage from './Actions';
// import Landing from './Landing';
// import AboutUs from './AboutUs';
// import Courses from './Courses';
// import FirebaseSent from './Firebase/FirebaseSent';
// import FireBaseViewactions from './Firebase/FireBaseViewactions';
// // import PostAssessmentDummy from './PostAssessmentDummy';
// import PostAssessment from './PostAssessment';
// import AssessmentDummy from './AssesmentDummy';
// import PreAssessment from './PreAssessment';
// import NotFound from './NotFound';
// // import TokenPairs from './Dummy/TokenPairs';
// import CertificateComplete from './CertificateComplete';
// import ModelHeader from './Header';
// import MyCourses from './MyCourses';
// import CourseVideo from './CourseVideoplay';
// import Profile from './Profile';
// import Specific from './Specific';
// import Faq  from './Faq'
// import Partners from './Partners';
// import Events from './Events';
// import ItemDetails from './Skillmarket'
// import ProfileEdit from "./ProfileEdit"


// //import Dashboard from "./ResultDashboard"

// ///--Admin panel--

// import AdminHeader from './ADMIN/Header';
// import AdminLogin from './ADMIN/Login';
// import Dashboardlms from './ADMIN/LmsDashboard';
// // import Users from './ADMIN/Users';
// import PaymentList from './ADMIN/PaymentList';
// // import Questionpage from './ADMIN/Questionpage';
// import AdminDummy from './ADMIN/Dummy';
// import CourseCreation from './ADMIN/CourseCreation';
// import CourseCreationEditPage from './ADMIN/CourseCreationEdit';
// import CourseList from './ADMIN/CourseList';
// import UsersList  from "./ADMIN/UsersList";
// import AdminQuestionpage from './ADMIN/QuestionPage';
// import UserDetails from './ADMIN/UserDetails';
// import AdminFireBaseViewactions from './ADMIN/FireBaseViewactions';
// import QuestionListEdit from './ADMIN/QuestionListEdit';
// import QuestionList from './ADMIN/QuestionList';
// import UserCourseList from './ADMIN/UserCourse';
// import ResultList from './ADMIN/ResultList';
// import UserResult from './ADMIN/UserResult';
// import AssessmentResult from './ADMIN/AssessmentResult';
// // import CourseCreationPageUI from './CourseCreationUI';
// import AdminEvents from './ADMIN/Events';
// import AuthRoute from './ADMIN/Authroute'; // Import the custom AuthRoute component
// import Result from './Result';
// import EventList from './ADMIN/EventList';
//  import Dashboard from './Dashboard/Dashboard';
// import ResultDashboard from './Dashboard/ResultDashboard';
// import ProfileEditDashboard from './Dashboard/ProfileEditDashboard';
// import CertificateDashboard from './Dashboard/CertificateDashboard';
// import ProgressDashboard from './Dashboard/ProgressDashboard';

// import { RequireAuth } from 'react-auth-kit'; 

// import Sample from "./SampleDummy"

// import { useEffect } from 'react';

// //----
// // import VideoChat from './MEET/VideoChat';
// // import MeetHeader from './MEET/Header';
// // import MeetHome from "./MEET/Home"
// import ReactGA from "react-ga4";
// import CouponList from './ADMIN/CouponList';
// import CreateCoupon from './ADMIN/CreateCoupon';
// import Ticket from './TestTicket';
// import AdminTicket from './ADMIN/Ticket';
// import RegisterUser from './ADMIN/RegisterUser';

// import CourseNewVideo from "./NewcourseVideo"

// import DummyProgress from "./Dashboard/newDummy"




// const TRACKING_ID  ="G-QYJX5DWCT0"

// ReactGA.initialize(TRACKING_ID);





// function Routers() {


// // useEffect(() => {
// //   ReactGA.initialize(TRACKING_ID);

// //   ReactGA.send({ hitType: "pageview",
// //    page: window.location.pathname + window.location.search, 
// //    title: "FABC LMS" });

// // }, [])
//     return (
//       <Routes>
//       {/* <Route path="/" element={<Home/>} /> */}
//       <Route path="/Dummy" element={<Dummy/>} />

//       <Route path="/Register" element={<Register/>}/>
//       <Route path="/ForgetPassword" element={<ForgetPassword/>}/>
//       <Route path="/Login" element={<Login/>}/>
//       <Route path="/ResetPassword" element={<ResetPassword/>}/>

//       <Route path="/tesvideo" element={<CourseNewVideo/>}/>
//       <Route path="/dummyProgress" element={<DummyProgress/>}/>



// {/* ----------path-------------- */}


// {/* <Route element={  */}
//           {/* // <RequireAuth fallbackPath="/Login"> */}
       
//             <>

//             <Route path="/Ticket" element={<Ticket/>} />



//       <Route path="/Header1" element={<ModelHeader/>} />

//       <Route path="/Header" element={<Header/>} />
//       <Route path="/Footer" element={<Footer/>} />
//       <Route path="/Chatui" element={<Chatui/>} />
//       <Route path="/Certificate" element={<Certificate/>} />
//       {/* <Route path="/Seeker" element={<SeekerResponse/>} /> */}
//       <Route path="/Notification" element={<Notification/>} />
//       <Route path="/About" element={<Aboutcompany/>} />
//       <Route path="/course" element={<CourseVideos/>} />
//       {/* <Route path="/" element={<Lms/>} /> */}
//       <Route path="/lms" element={<Lms/>} />

//       <Route path="/footer2" element={<Footertwo/>} />
//       <Route path="/validator" element={<Validators/>} />
//       <Route path="/question" element={<Questionpage/>} />
//       <Route path="/manage" element={<Manage/>} />
//       <Route path="/wallet" element={<Wallet/>} />
//       <Route path="/Assessment" element={<Assessment/>} />
//       <Route path="/PostAssessment/:courseName/:course_id" component={<PostAssessment/>}/>
//       {/* <Route path="/PostAssessmentDummy" element={<PostAssessmentDummy/>} /> */}
//       <Route path="/PostAssessment" element={<PostAssessment/>} />
//       <Route path="/PreAssessment" element ={<PreAssessment/>}/>
//       <Route path="/NotFound" element={<NotFound/>} />
      
      

//       <Route path="/CertificateComplete" element={<CertificateComplete/>} />

//       <Route path="/AssessmentDummy" element={<AssessmentDummy/>} />

//       <Route path="/popup" element={<Popup/>} />
//       <Route path="/skill" element={<SkillAssessment/>} />
//       {/* <Route path="/ID" element={<SkillId/>} /> */}
//       <Route path="/PostSkillAssessment" element={<PostSkillAssessment/>} />
//       <Route path="/congratulation" element={<Congratulation/>}/>
//      {/* <Route path="/video" element={<CourseVideos/>}/> */}
//       <Route path="/Register" element={<Register/>}/>
//       <Route path="/verify" element={<Verify/>}/>
//       <Route path="/precourse" element={<PreCourse/>}/>
//       <Route path="/verify-login" element={<VerifyLogin />}/>
//       <Route path="/Videoplayer" element={<VideoPlayer />}/>
//       <Route path="/view-actions" element={<ActionsPage />}/>
//       <Route path="/" element={<Landing />}/>
//       <Route path="/landing" element={<Landing />}/>
//       <Route path="/FAQ" element={<Faq />}/>
//       <Route path="/Partners" element={<Partners />}/>
//       <Route path="/Events" element={<Events />}/>

//       <Route path="/profile/ItemDetails" element={<ItemDetails />} />



//       <Route path="/AboutUs" element={<AboutUs />}/>
//       <Route path="/Courses" element={<Courses />}/>
//       <Route path="/MyProgress" element={<MyCourses />}/>


//       <Route path="/MyProgress" element={<MyCourses />}/>

//       <Route path="/ProfileEdit" element={<ProfileEdit />}/>

//       <Route path="/FirebaseSent" element={<FirebaseSent />}/>
//       <Route path="/FireBaseViewactions" element={<FireBaseViewactions />}/>

      

//       <Route path="/CoursePlay" element={<CourseVideo />}/>
//       <Route path="/Profile" element={<Profile />}/>

//       <Route path="/Specific" element={<Specific />}/>
//       <Route path="/Result" element={<Result />}/>


//       <Route path="/Dashboard" element={<Dashboard />}/>

//       <Route path="/Sample" element={<Sample />}/>


//      {/*Dashboard*/}
//      <Route path="/dashboard/Dashboard" element={<Dashboard/>}/>
//       <Route path="/dashboard/resultdashboard" element={<ResultDashboard/>}/>
//       <Route path="/dashboard/ProfileEditDashboard" element={<ProfileEditDashboard/>}/>
//       <Route path="/dashboard/CertificateDashboard" element={<CertificateDashboard/>}/>
//       <Route path="/dashboard/ProgressDashboard" element={<ProgressDashboard/>}/>


//       </>
//           {/* // </RequireAuth>
//         // } /> */}

// {/*--------------------------------ADMIN--------------------------------------------------*/}

//         <Route path="/admin" element={<AdminLogin />} />
//         <Route path="/admin/header" element={<AdminHeader />} />
//       {/* <Route path="/home" element={<Home />} /> */}
//       <Route path="/admin/ticket" element={<AdminTicket/>} />
      

//       <Route path="/admin/RegisterUser" element={<RegisterUser/>} />

      
//       <Route path="/admin/Dashboard" element={<Dashboardlms />} />

//       <Route path="/admin/UsersList" element={<UsersList />} />
//       <Route path="/admin/PaymentHistory" element={<PaymentList />} />

      
//       <Route path="/admin/Questionpage" element={<AdminQuestionpage />} />
//       <Route path="/admin/Dummy" element={<AdminDummy />} />
      

//       <Route path="/admin/QuestionEdit" element={<QuestionListEdit />} />

//       <Route path="/admin/CourseCreation" element={<CourseCreation />} />
//       <Route path="/CourseAction" element={<AdminFireBaseViewactions />} />

//       <Route path="/admin/CourseEdit" element={<CourseCreationEditPage />} />


//       <Route path="/admin/CourseList" element={<CourseList />} />
//       <Route path="/admin/UserCourseList" element={<UserCourseList />} />

//       <Route path="/admin/UserDetails" element={<UserDetails />} />
//       <Route path="/admin/QuestionList" element={<QuestionList />} />
//       <Route path="/admin/ResultList" element={<ResultList />} />
//       <Route path="/admin/UserResult" element={<UserResult />} />
//       <Route path="/admin/AssessmentResult" element={<AssessmentResult />} />

//       {/* <Route path="/admin/courseUI" element={<CourseCreationPageUI />} /> */}
//       <Route path="/admin/Events" element={<AdminEvents />} />


//       <Route path="/admin/EventList" element={<EventList />} />

//       <Route path="/admin/couponList" element={<CouponList />} />
//       <Route path="/admin/createCoupon" element={<CreateCoupon />} />

 





//              </Routes>
//   );
// }

// export default Routers; 

















import React, { Suspense , startTransition, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import GoogleTranslate from './GoogleTranslate/GoogleTranslate';


const ProtectedRoute = React.lazy(() => import('./ProductedRoute'));

const Home = React.lazy(() => import('./NewLanding'));

const Dummy = React.lazy(() => import('./Dummy'));
const Register = React.lazy(() => import('./Register'));
const ForgetPassword = React.lazy(() => import('./ForgetPassword'));
const Login = React.lazy(() => import('./Login'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const CourseNewVideo = React.lazy(() => import('./NewcourseVideo'));
const AdminLogin = React.lazy(() => import('./ADMIN/Login'));
const Ticket = React.lazy(() => import('./TestTicket'));
const ModelHeader = React.lazy(() => import('./Header'));
const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const Chatui = React.lazy(() => import('./Chatui'));
const Certificate = React.lazy(() => import('./Certificate'));
const Notification = React.lazy(() => import('./Notification'));
const Aboutcompany = React.lazy(() => import('./Aboutcompany'));
const CourseVideos = React.lazy(() => import('./CourseVideos'));
const Lms = React.lazy(() => import('./Lms'));
const Footertwo = React.lazy(() => import('./Footertwo'));
const Validators = React.lazy(() => import('./Validators'));
const Questionpage = React.lazy(() => import('./Questionpage'));
const Manage = React.lazy(() => import('./Manage'));
const Wallet = React.lazy(() => import('./Wallet'));
const Assessment = React.lazy(() => import('./Assesment'));
const PostAssessment = React.lazy(() => import('./PostAssessment'));
const PreAssessment = React.lazy(() => import('./PreAssessment'));
const NotFound = React.lazy(() => import('./NotFound'));
const CertificateComplete = React.lazy(() => import('./CertificateComplete'));
const AssessmentDummy = React.lazy(() => import('./AssesmentDummy'));
const Popup = React.lazy(() => import('./Popup'));
const SkillAssessment = React.lazy(() => import('./SkillAssessment'));
const PostSkillAssessment = React.lazy(() => import('./PostSkillAssessment'));
const Congratulation = React.lazy(() => import('./Congratulation'));
const Verify = React.lazy(() => import('./Verify'));
const PreCourse = React.lazy(() => import('./PreCourse'));
const VerifyLogin = React.lazy(() => import('./VerifyLogin'));
const VideoPlayer = React.lazy(() => import('./VideoPlayer'));
const ActionsPage = React.lazy(() => import('./Actions'));
const Landing = React.lazy(() => import('./Landing'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const Courses = React.lazy(() => import('./Courses'));
const FirebaseSent = React.lazy(() => import('./Firebase/FirebaseSent'));
const FireBaseViewactions = React.lazy(() => import('./Firebase/FireBaseViewactions'));
const MyCourses = React.lazy(() => import('./MyCourses'));
const CourseVideo = React.lazy(() => import('./CourseVideoplay'));
const Profile = React.lazy(() => import('./Profile'));
const Specific = React.lazy(() => import('./Specific'));
const Faq = React.lazy(() => import('./Faq'));
const Partners = React.lazy(() => import('./Partners'));
const Events = React.lazy(() => import('./Events'));
const Recaptcha = React.lazy(() => import('./Recaptcha'));

const ItemDetails = React.lazy(() => import('./Skillmarket'));
const ProfileEdit = React.lazy(() => import('./ProfileEdit'));
const AdminHeader = React.lazy(() => import('./ADMIN/Header'));
const Dashboardlms = React.lazy(() => import('./ADMIN/LmsDashboard'));
const PaymentList = React.lazy(() => import('./ADMIN/PaymentList'));
const AdminDummy = React.lazy(() => import('./ADMIN/Dummy'));
const CourseCreation = React.lazy(() => import('./ADMIN/CourseCreation'));
const AdminFireBaseViewactions = React.lazy(() => import('./ADMIN/FireBaseViewactions'));
const CourseCreationEditPage = React.lazy(() => import('./ADMIN/CourseCreationEdit'));
const CourseList = React.lazy(() => import('./ADMIN/CourseList'));
const UsersList = React.lazy(() => import('./ADMIN/UsersList'));
const AdminQuestionpage = React.lazy(() => import('./ADMIN/QuestionPage'));
const UserDetails = React.lazy(() => import('./ADMIN/UserDetails'));
const QuestionListEdit = React.lazy(() => import('./ADMIN/QuestionListEdit'));
const QuestionList = React.lazy(() => import('./ADMIN/QuestionList'));
const UserCourseList = React.lazy(() => import('./ADMIN/UserCourse'));
const ResultList = React.lazy(() => import('./ADMIN/ResultList'));
const UserResult = React.lazy(() => import('./ADMIN/UserResult'));
const AssessmentResult = React.lazy(() => import('./ADMIN/AssessmentResult'));
const AdminEvents = React.lazy(() => import('./ADMIN/Events'));
const EventList = React.lazy(() => import('./ADMIN/EventList'));
const Result = React.lazy(() => import('./Result'));
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const ResultDashboard = React.lazy(() => import('./Dashboard/ResultDashboard'));
const ProfileEditDashboard = React.lazy(() => import('./Dashboard/ProfileEditDashboard'));
const CertificateDashboard = React.lazy(() => import('./Dashboard/CertificateDashboard'));
const ProgressDashboard = React.lazy(() => import('./Dashboard/ProgressDashboard'));
const Sample = React.lazy(() => import('./SampleDummy'));
const CouponList = React.lazy(() => import('./ADMIN/CouponList'));
const CreateCoupon = React.lazy(() => import('./ADMIN/CreateCoupon'));
const AdminTicket = React.lazy(() => import('./ADMIN/Ticket'));
const RegisterUser = React.lazy(() => import('./ADMIN/RegisterUser'));
const Loader = React.lazy(() => import("./Loader"));
const DashboardWallet = React.lazy(() => import("./Dashboard/Wallet"));
const DummyProgress = React.lazy(() => import("./Dashboard/newDummy"));
const EditCoupon = React.lazy(() => import("./ADMIN/CouponEdit"));
const ImportUser = React.lazy(() => import("./ADMIN/ImportUser"));
const ImportEnrollments = React.lazy(() => import("./ADMIN/ImportEnrollments"));
const WebinarRegistrationForm = React.lazy(() => import("./WebinarRegistration"));
const DeleteProfile = React.lazy(() => import("./DeleteProfile"));
const MintedUsers = React.lazy(() => import("./ADMIN/MintedUsers"));

const FlowChart = React.lazy(() => import("./Flowchart"));
const DNAtracking = React.lazy(() => import("./ADMIN/DNAtracking"));
const DNAusers = React.lazy(() => import("./ADMIN/DNAusersList"));




function Routers() {

  const location = useLocation();



const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  startTransition(() => {
    // Simulate a data fetch or other async operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); 
  });
}, []);

if (isLoading) {
  return <Loader />;
}



    return (
      <Suspense fallback={<Loader/>}>

        <Routes>
            {/* <Route path="/" element={<Landing />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/course-home" element={<Home />} />


            
            {/* {startTransition(() => (

            <> */}



            <Route path="/dummyProgress" element={<DummyProgress/>}/>
            <Route path="/webinar-reg" element={<WebinarRegistrationForm />} />

            
         
            <Route path="/About" element={<Aboutcompany />} />

            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Courses" element={<Courses />} />
            <Route path="/Dummy" element={<Dummy />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/tesvideo" element={<CourseNewVideo />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/FAQ" element={<Faq />} />
            <Route path="/Partners" element={<Partners />} />
            <Route path="/Events" element={<Events />} />
            <Route path="/flow-chart" element={<FlowChart />} />
            {/* <Route path="/flow-chart" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><FlowChart /></ProtectedRoute>} /> */}

            <Route path="/Ticket" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Ticket /></ProtectedRoute>} />
            <Route path="/Header1" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ModelHeader /></ProtectedRoute>} />
            <Route path="/Header" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Header /></ProtectedRoute>} />
            <Route path="/Footer" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Footer /></ProtectedRoute>} />
            <Route path="/Chatui" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Chatui /></ProtectedRoute>} />
            <Route path="/Certificate" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Certificate /></ProtectedRoute>} />
            <Route path="/Notification" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Notification /></ProtectedRoute>} />
            <Route path="/course" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><CourseVideos /></ProtectedRoute>} />
            <Route path="/lms" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Lms /></ProtectedRoute>} />
            <Route path="/footer2" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Footertwo /></ProtectedRoute>} />
            <Route path="/validator" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Validators /></ProtectedRoute>} />
            <Route path="/question" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Questionpage /></ProtectedRoute>} />
            <Route path="/manage" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Manage /></ProtectedRoute>} />
            <Route path="/wallet" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Wallet /></ProtectedRoute>} />
            <Route path="/Assessment" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Assessment /></ProtectedRoute>} />
            <Route path="/PostAssessment/:courseName/:course_id" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><PostAssessment /></ProtectedRoute>} />
            <Route path="/PostAssessment" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><PostAssessment /></ProtectedRoute>} />
            <Route path="/PreAssessment" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><PreAssessment /></ProtectedRoute>} />
            <Route path="/NotFound" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><NotFound /></ProtectedRoute>} />
            <Route path="/CertificateComplete" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><CertificateComplete /></ProtectedRoute>} />
            <Route path="/AssessmentDummy" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><AssessmentDummy /></ProtectedRoute>} />
            <Route path="/popup" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Popup /></ProtectedRoute>} />
            <Route path="/skill" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><SkillAssessment /></ProtectedRoute>} />
            <Route path="/PostSkillAssessment" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><PostSkillAssessment /></ProtectedRoute>} />
            <Route path="/congratulation" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Congratulation /></ProtectedRoute>} />
            <Route path="/Register" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Register /></ProtectedRoute>} />
            <Route path="/verify" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Verify /></ProtectedRoute>} />
            <Route path="/PreCourse" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><PreCourse /></ProtectedRoute>} />
            <Route path="/verify-login" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><VerifyLogin /></ProtectedRoute>} />
            <Route path="/Videoplayer" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><VideoPlayer /></ProtectedRoute>} />
            <Route path="/view-actions" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ActionsPage /></ProtectedRoute>} />
            <Route path="/profile/ItemDetails" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ItemDetails /></ProtectedRoute>} />
            <Route path="/MyProgress" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><MyCourses /></ProtectedRoute>} />
            <Route path="/ProfileEdit" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ProfileEdit /></ProtectedRoute>} />
            <Route path="/FirebaseSent" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><FirebaseSent /></ProtectedRoute>} />
            <Route path="/FireBaseViewactions" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><FireBaseViewactions /></ProtectedRoute>} />
            <Route path="/CoursePlay" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><CourseVideo /></ProtectedRoute>} />
            <Route path="/Profile" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Profile /></ProtectedRoute>} />
            <Route path="/Specific" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Specific /></ProtectedRoute>} />
            <Route path="/Result" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Result /></ProtectedRoute>} />
            <Route path="/Dashboard" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Dashboard /></ProtectedRoute>} />
            <Route path="/Sample" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Sample /></ProtectedRoute>} />
            <Route path="/dashboard/DashboardWallet" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><DashboardWallet /></ProtectedRoute>} />
            <Route path="/dashboard/Dashboard" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Dashboard /></ProtectedRoute>} />
            <Route path="/dashboard/resultdashboard" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ResultDashboard /></ProtectedRoute>} />
            <Route path="/dashboard/ProfileEditDashboard" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ProfileEditDashboard /></ProtectedRoute>} />
            <Route path="/dashboard/CertificateDashboard" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><CertificateDashboard /></ProtectedRoute>} />
            <Route path="/dashboard/ProgressDashboard" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><ProgressDashboard /></ProtectedRoute>} />
            <Route path="/delete-profile" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><DeleteProfile /></ProtectedRoute>} />

            <Route path="/recaptcha" element={<ProtectedRoute allowedRoles={['admin','seeker', 'subadmin']}><Recaptcha /></ProtectedRoute>} />

            <Route path="/admin/login" element={<AdminLogin />} />

            <Route path="/admin/header" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true} ><AdminHeader /></ProtectedRoute>} />
            <Route path="/admin/ticket" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><AdminTicket /></ProtectedRoute>} />
            <Route path="/admin/RegisterUser" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><RegisterUser /></ProtectedRoute>} />
            <Route path="/admin/dashboard" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><Dashboardlms /></ProtectedRoute>} />
            <Route path="/admin/UsersList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><UsersList /></ProtectedRoute>} />
            <Route path="/admin/mintedusers" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><MintedUsers /></ProtectedRoute>} />
            <Route path="/admin/PaymentHistory" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><PaymentList /></ProtectedRoute>} />
            <Route path="/admin/Questionpage" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><AdminQuestionpage /></ProtectedRoute>} />
            <Route path="/admin/Dummy" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><AdminDummy /></ProtectedRoute>} />
            <Route path="/admin/QuestionEdit" element={<ProtectedRoute allowedRoles={['admin']}><QuestionListEdit /></ProtectedRoute>} />
            <Route path="/admin/CourseCreation" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><CourseCreation /></ProtectedRoute>} />
            <Route path="/CourseAction" element={<ProtectedRoute allowedRoles={['admin']}><AdminFireBaseViewactions /></ProtectedRoute>} />
            <Route path="/admin/CourseEdit" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><CourseCreationEditPage /></ProtectedRoute>} />
            <Route path="/admin/CourseList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><CourseList /></ProtectedRoute>} />
            <Route path="/admin/UserCourseList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><UserCourseList /></ProtectedRoute>} />
            <Route path="/admin/UserDetails" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><UserDetails /></ProtectedRoute>} />
            <Route path="/admin/QuestionList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><QuestionList /></ProtectedRoute>} />
            <Route path="/admin/ResultList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><ResultList /></ProtectedRoute>} />
            <Route path="/admin/UserResult" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><UserResult /></ProtectedRoute>} />
            <Route path="/admin/AssessmentResult" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><AssessmentResult /></ProtectedRoute>} />
            <Route path="/admin/Events" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><AdminEvents /></ProtectedRoute>} />
            <Route path="/admin/EventList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><EventList /></ProtectedRoute>} />
            <Route path="/admin/couponList" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><CouponList /></ProtectedRoute>} />
            <Route path="/admin/createCoupon" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><CreateCoupon /></ProtectedRoute>} />
            <Route path="/admin/CouponEdit" element={<ProtectedRoute allowedRoles={['admin']} adminRoute={true}><EditCoupon /></ProtectedRoute>} />
            <Route path="/admin/dna-tracking" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><DNAtracking /></ProtectedRoute>} />
            <Route path="/admin/dna-users" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><DNAusers /></ProtectedRoute>} />


            <Route path="/admin/ImportUser" element={<ProtectedRoute allowedRoles={['admin', 'subadmin']} adminRoute={true}><ImportUser /></ProtectedRoute>} />
            <Route path="/admin/users-enroll" element={<ProtectedRoute allowedRoles={['admin']} adminRoute={true}><ImportEnrollments /></ProtectedRoute>} />

            <Route path="*" element={<NotFound />} />


            {/* </>
              ))} */}
        </Routes>
    </Suspense>
    );
}

export default Routers;






