// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
// import './index.css';
// import reportWebVitals from './reportWebVitals';
// import Routers from './route';
// import { store, persistor } from './store';
// import { Provider } from 'react-redux';
// import VideoPlayer from './VideoPlayer';
// // import Login from './Google/GoogleLogin';
// // import Logout from './Google/GoogleLogout';
// import { useEffect } from 'react';
// import {gapi} from "gapi-script"
// //import { AppContextProvider } from './MEET/appContext';


// import ReactGA from "react-ga4";
// const TRACKING_ID  ="G-QYJX5DWCT0"

// ReactGA.initialize(TRACKING_ID);






// useEffect(() => {
//   ReactGA.initialize(TRACKING_ID);

//   ReactGA.send({ hitType: "pageview",
//    page: window.location.pathname + window.location.search, 
//    title: "FABC LMS" });

// }, [])


// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
  
//   <Router>

//     {/* <AppContextProvider> */}
//      <Provider store={store}>
//      {/* <VideoPlayer videoId="OABa_PoxxA8" />  */}
//     <Routers/>
//     </Provider>
//     {/* </AppContextProvider> */}
//   </Router>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();










import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './route';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import VideoPlayer from './VideoPlayer';
import ReactGA from "react-ga4";

// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

import { AuthProvider } from "react-auth-kit"
import GoogleTranslate from './GoogleTranslate/GoogleTranslate';


const projectId = '58a936400ecc50aa6c05bd0215a14824'
 
const mainnet = {
  chainId: 42161,  
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',  
  rpcUrl: 'https://arb1.arbitrum.io/rpc'  
}
const polygon = {
  chainId: 137, 
  name: 'Polygon',
  currency: 'MATIC', 
  explorerUrl: 'https://explorer-mainnet.maticvigil.com/', 
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/', 
};

const amoy = {
  chainId: 80002, 
  name: 'Polygon Amoy Testnet',
  currency: 'MATIC', 
  explorerUrl: 'https://amoy.polygonscan.com/', 
  rpcUrl: 'https://rpc-amoy.polygon.technology/', 
};



const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://edu.d5art.com', 
  icons: ['https://edu.d5art.com/assets/logo.png']

}

const ethersConfig = defaultConfig({

  metadata,

  enableEIP6963: true, 
  enableInjected: true, 
  enableCoinbase: true, 
  rpcUrl: '...', 
  defaultChainId: 137, 

})
createWeb3Modal({
  ethersConfig,
  chains: [mainnet,polygon,amoy],
  projectId,
  themeVariables: {
  '--w3m-accent	':'#0db1a9',
  },
 
  enableAnalytics: true 
})




const TRACKING_ID   ="G-C1K3LLSQGB";
// const TRACKING_ID  = "G-6266M43C2N";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search, 
      title: "FABC LMS"
    });
  }, []);


  console.log("Process env", process.env.MORALIS_API)
  return (

    
    <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    cookieDomain={window.location.hostname}
    cookieSecure={false} 
   >
     <Provider store={store}>

    <Router>

     
        <Routers/>
       

     
    </Router>
    </Provider>
   </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

reportWebVitals();


