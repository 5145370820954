// import React from "react";
// import StartFirebase from "./FirebaseConfig";
// import{ref,set,get,update} from "firebase/database";
// import { useSelector } from "react-redux";

//  export class FirebaseSent extends React.Component{


//     constructor (props){
//         super(props);
//         this.state ={
//             db:"",
//             Email:"",
//             Course:"",
//             Video:"",
//             Actions:""
//         }

//         this.interface = this.interface.bind(this);
//     }

//     componentDidMount(){
//         this.setState({
//             db:StartFirebase()
//         });
//     }

//     render(){
//         const seeker_email = useSelector((state) => state.seeker_email);

//         const Email = seeker_email
//         console.log("seeker_email",seeker_email, Email)
//         const actions = useSelector((state) => state.actions);

      
//   return(
//     <>
//       <label>Email</label>
//       <input type="text" name="Email" value={this.state.Email} 
//       onChange={e=>{this.setState({Email: e.target.value})}}/>
//       <br/>
//       <input type="text" name="Course" value={this.state.Course} 
//       onChange={e=>{this.setState({Course: e.target.value})}}/>
//       <input type="text" name="Video" value={this.state.Video} 
//       onChange={e=>{this.setState({Video: e.target.value})}}/>
//       <br/>
//       <input type="text" name="Actions" value={this.state.Actions} 
//       onChange={e=>{this.setState({Email: e.target.value})}}/>
//       <br/>
//       <br/>



// <button id="addBtn" onClick={this.interface}> Add Data</button>
// <button id="updateBtn" onClick={this.interface}> update Data</button>
// <button id="getBtn" onClick={this.interface}> get Data</button>
    
//     </>
//   )


//     }

//     interface(event){
//         const id= event.target.id;
  
//         if(id==='addBtn'){
//             this.insertData();
//         }
//         else if(id==="updateBtn"){
//             this.updateData();
//         }
//         else if (id ==="getBtn"){
//             this.interface();
//         }


//     }

//   getAllInputs(){
//     return{
//         Email: this.state.Email,
//         Course: this.state.Course,
//         Video: this.state.Video,
//         Actions : this.state.Actions
//     }
//   }

//   insertData(){
//     const db = this.state.db;
//     const data = this.getAllInputs();

//     set(ref(db,'UserAction/'+ data.Email),
//     {
//         Course: data.Course,
//         Video: data.Video,
//         Actions:data.Actions
//     })
//     .then(()=>{console.log("Data sent successfully")});
//     .catch((error)=>{console.log("there was an error", error)});

//   }


//   updateData(){
//     const db = this.state.db;
//     const data = this.getAllInputs();

//     update(ref(db,'UserAction/'+ data.Email),
//     {
//         Course: data.Course,
//         Video: data.Video,
//         Actions:data.Actions
//     })
//     .then(()=>{console.log("Data update successfully")});
//     .catch((error)=>{console.log("there was an error", error)});

//   }



// }



// import React, { useState, useEffect } from "react";
// import StartFirebase from "./FirebaseConfig";
// import { ref, set, update, get } from "firebase/database";
// import { useSelector } from "react-redux";

// const FirebaseSent = (email, courseName , actions) => {
//   const [db, setDb] = useState("");
//   const [email, setEmail] = useState("");
//   const [course, setCourse] = useState("");
//   const [video, setVideo] = useState("");
//   const [actions, setActions] = useState("");
  
//   // Set initial values for Email and Actions using useSelector

//   const validatorEmail = useSelector((state) => state.seeker_email);
// const actionsFromRedux = useSelector((state) => state.actions);

//   useEffect(() => {
//     setDb(StartFirebase());
    
//     // Set initial values for Email and Actions
//     setEmail(validatorEmail);
//     setActions(actionsFromRedux);
//   }, [validatorEmail, actionsFromRedux]);

//   const interfaceHandler = (event) => {
//     const id = event.target.id;

//     if (id === "addBtn") {
//       insertData();
//     } else if (id === "updateBtn") {
//       updateData();
//     } else if (id === "getBtn") {
//       getData();
//     }
//   };

//   const getAllInputs = () => {
//     return {
//       Email: email,
//       Course: course,
//       Video: video,
//       Actions: actions
//     };
//   };



//   const insertData = () => {
//     const data = getAllInputs();
//     const sanitizedEmail = data.Email.replace(".com", "");
//     console.log("sanitizedEmail",sanitizedEmail)

//     set(ref(db, "UserAction/" + sanitizedEmail  ), {
//       Course: data.Course,
//       Video: data.Video,
//       Actions: data.Actions
//     })
//       .then(() => {
//         console.log("Data sent successfully");
//       })
//       .catch((error) => {
//         console.log("There was an error", error);
//       });
//   };

//   const updateData = () => {
//     const data = getAllInputs();
//     const sanitizedEmail = data.Email.replace(".com", "");

//     update(ref(db, "UserAction/" +sanitizedEmail), {
//       Course: data.Course,
//       Video: data.Video,
//       Actions: data.Actions
//     })
//       .then(() => {
//         console.log("Data updated successfully");
//       })
//       .catch((error) => {
//         console.log("There was an error", error);
//       });
//   };

//   const getData = () => {
//     const data = getAllInputs();
//     const sanitizedEmail = data.Email.replace(".com", "");

//     get(ref(db, "UserAction/" + sanitizedEmail))
//       .then((snapshot) => {
//         if (snapshot.exists()) {
//           const userData = snapshot.val();
//           console.log("Retrieved data:", userData);
//         } else {
//           console.log("No data found");
//         }
//       })
//       .catch((error) => {
//         console.log("There was an error", error);
//       });
//   };

//   return (
//     <>
//       <label>Email</label>
//       <input
//         type="text"
//         name="Email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//       />
//       <br />
//       <label>Course</label>
//       <input
//         type="text"
//         name="Course"
//         value={course}
//         onChange={(e) => setCourse(e.target.value)}
//       />
//         <label>video</label>
//       <input
//         type="text"
//         name="Video"
//         value={video}
//         onChange={(e) => setVideo(e.target.value)}
//       />
//       <br />
//       <label>Actions</label>
//       <input
//         type="text"
//         name="Actions"
//         value={actions}
//         onChange={(e) => setActions(e.target.value)}
//       />
//       <br />
//       <br />

//       <button id="addBtn" onClick={interfaceHandler}>
//         Add Data
//       </button>
//       <button id="updateBtn" onClick={interfaceHandler}>
//         Update Data
//       </button>
//       <button id="getBtn" onClick={interfaceHandler}>
//         Get Data
//       </button>
//     </>
//   );
// };

// export default FirebaseSent;


//////////////-----------------










// import React, { useState, useEffect } from "react";
// import StartFirebase from "./FirebaseConfig";
// import { ref, update } from "firebase/database";

// const FirebaseSent = ({ email, courseName, actions  ,watchedPercentage}) => {
//   const [db, setDb] = useState(null);
 


//   // const handleWatchedPercentageUpdate = (percentage) => {
//   //   setWatchedPercentage(percentage);
//   // };



// console.log(" firebase_data",watchedPercentage,email, courseName, actions )

//   useEffect(() => {
//     const initializeFirebase = async () => {
//       const firebaseDb = await StartFirebase();
//       setDb(firebaseDb);
//     };

//     initializeFirebase();
//   }, []);

// //   useEffect(() => {
// //     const updateData = async () => {
// //       // Trigger the update only if email is defined
// //       if (email && actions && db) {
// //         const sanitizedEmail = email.replace(".com", "");
// //             const latestAction = actions[actions.length - 1];


// //         try {
// //           await update(ref(db, `UserAction1/${sanitizedEmail}/${courseName}`), {
// //          //   Course: courseName,
// //             Actions: actions,
// //           });

// //           console.log("Data updated successfully");
// //         } catch (error) {
// //           console.log("There was an error", error);
// //         }
// //       }
// //     };

// //     updateData();
// //   }, [email, courseName, actions, db]);




// // useEffect(() => {
// //     const updateData = async () => {
// //       // Trigger the update only if email is defined
// //       if (email && actions && db) {
// //         const sanitizedEmail = email.replace(".com", "");
  

// //         console.log("actions",actions)
// //         try {
// //           // Loop through actions array
// //           for (const action of actions) {
// //             if (action.courseName === courseName) {
// //                 console.log("action.courseName" , action.courseName  ,courseName)
// //               await update(ref(db, `UserAction2/${sanitizedEmail}/${courseName}`), {
// //                 Actions: actions,
// //               });
  
// //               console.log("Data updated successfully");
// //               // Assuming you want to update only once for a matching courseName, break the loop
// //               break;
// //             }
// //           }
// //         } catch (error) {
// //           console.log("There was an error", error);
// //         }
// //       }
// //     };
  
// //     updateData();
// //   }, [email, courseName, actions, db]);
  



// useEffect(() => {
//     const updateData = async () => {
//       if (email && actions && db) {
//         const sanitizedEmail = email.replace(".com", "");
  
//         try {
//           // const matchingActions = actions.filter(action => action.courseName === courseName);
  
//           await update(ref(db, `UserAction3/${sanitizedEmail}/${courseName}`), {
//             Actions: actions,
//             watchedPercentage:watchedPercentage
//           });
  
//           console.log("Data updated successfully");
//         } catch (error) {
//           console.log("There was an error", error);
//         }
//       }
//     };
  
//     updateData();
//   }, [email, courseName, actions, watchedPercentage,db]);
  
//   return (
//     <>
      
//     </>
//   );
// };

// export default FirebaseSent;











// import React, { useState, useEffect } from "react";
// import StartFirebase from "./FirebaseConfig";
// import { ref, update, get } from "firebase/database";

// const FirebaseSent = ({ email, courseName, actions, watchedPercentage }) => {
//   const [db, setDb] = useState(null);

//   console.log("email firebase", email);

//   useEffect(() => {
//     const initializeFirebase = async () => {
//       const firebaseDb = await StartFirebase();
//       setDb(firebaseDb);
//     };

//     initializeFirebase();
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (email && db) {
//         const sanitizedEmail = email.replace(".com", "");

//         try {
//           // Fetch existing data from Firebase
//           const snapshot = await get(ref(db, `UserAction3/${sanitizedEmail}/${courseName}`));
//           const existingData = snapshot.val();

//           console.log("Existing data from Firebase:", existingData);

//           // Optionally, you can update local storage here if needed
//           // localStorage.setItem("videoData", JSON.stringify(existingData));

//         } catch (error) {
//           console.log("Error fetching data from Firebase", error);
//         }
//       }
//     };

//     fetchData();
//   }, [email, courseName, db]);

//   const updateData = async () => {
//     if (email && actions && db) {
//       const sanitizedEmail = email.replace(".com", "");

//       try {
//         // const matchingActions = actions.filter((action) => action.courseName === courseName);

//         await update(ref(db, `UserAction3/${sanitizedEmail}/${courseName}`), {
//           Actions: actions,
//           watchedPercentage: watchedPercentage,
//         });

//         console.log("Data updated successfully");
//       } catch (error) {
//         console.log("There was an error", error);
//       }
//     }
//   };

//   useEffect(() => {
//     updateData();
//   }, [email, courseName, actions, watchedPercentage, db]);

//   return <></>; // You can customize the JSX as needed
// };

// export default FirebaseSent;












// import React, { useEffect } from "react";
// import { get, ref, update } from "firebase/database";

// import StartFirebase from "./FirebaseConfig"; 

// const FirebaseSent = ({ email, courseName, actions, watchedPercentage, watchedTime }) => {
//   const db= StartFirebase();


//   console.log("email, courseName, actions, watchedPercentage, watchedTime",email, courseName, actions, watchedPercentage, watchedTime)
//   useEffect(() => {
//     const sendToFirebase = async () => {
//       const sanitizedEmail = email.replace(".com", "");
//       try {
//         const snapshot = await get(ref(db, `UserAction4/${sanitizedEmail}/${courseName}`));
//         const existingData = snapshot.val() || {};

//         let existingActions = existingData.Actions || [];

//         const updatedActions = [...existingActions, ...actions];

//         await update(ref(db, `UserAction4/${sanitizedEmail}/${courseName}`), {
//           Actions: updatedActions,
//           watchedPercentage,
//           watchedTime,
//         });

//         console.log("Data updated successfully");
//       } catch (error) {
//         console.log("Error updating data:", error);
//       }
//     };

//     sendToFirebase();
//   }, [email, courseName, actions, watchedPercentage, watchedTime]);

//   return null;
// };

// export default FirebaseSent;












import React, { useEffect, useState } from "react";
import { ref, update, get } from "firebase/database";
import StartFirebase from "./FirebaseConfig";

const FirebaseSent = ({ email, courseName, allActions, watchedPercentage }) => {
  const [db, setDb] = useState(null);

  const lastAction = allActions.length > 0 ? allActions[allActions.length - 1] : null;
console.log(lastAction)

  useEffect(() => {
    const initializeFirebase = async () => {
      const firebaseDb = await StartFirebase();
      setDb(firebaseDb);
    };

    initializeFirebase();
  }, []);

  useEffect(() => {
    const sendToFirebase = async () => {
      if (db) {
        const sanitizedEmail = email.replace(".com", "");
        const actionsRef = ref(db, `UserAction3/${sanitizedEmail}/${courseName}`);

        try {
          // Merge existing actions with new actions
          const snapshot = await get(actionsRef);
          const existingData = snapshot.val() || {};
          const updatedActions = [...(existingData.Actions || []), ...allActions];

          await update(actionsRef, {
            Actions: updatedActions,
            watchedPercentage,
          });

          console.log("Data updated successfully");
        } catch (error) {
          console.error("Error updating data:", error);
        }
      }
    };

    sendToFirebase();
  }, [db, email, courseName, allActions, watchedPercentage]);

  return null;
};

export default FirebaseSent;
